@import url(//fonts.googleapis.com/css?family=Oswald:700);


body{ 
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    background-color: #e1e1e1;
    padding: 0px;
    margin: auto;
}

.name-style {
    text-transform:none;
    font-size: 2.2em;
    margin: 50px 0 0 0px;
    opacity: 0;
    animation: name-anim 3s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}

@keyframes name-anim {
    from {margin: 50px 0 0 0px;opacity: 0; }
    to { margin: 50px 0 0 140px;opacity: 1; }
}

.name-style-cat {
    position: absolute;
    top: 10px; 
    left: 10px;
    font-size: 1.5em;
    text-decoration: none;
    color: #fff;
    opacity: 0.8;
    display: none;
}


.box-main{ 
    display: flex;
    flex-wrap: wrap;
    margin: 100px 0px 0px 0px;
    transition: 1s;
}

/* All Slider */
.slider-stage{
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100%;
    animation: stg-anime 3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes stg-anime {
    from {opacity: 0;}
    to {opacity: 1;}
}

/* ---------------------loader logo---------------------*/

.loader-logo{
    position:fixed;
    width: 130px;
    height: 130px;
    left: 40%;
    top: 30%;
    background-color: #ffffff;
    z-index: 20;
    border-radius: 50%;
    background-image: url(cssimg/loading/logo-gredient.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90%;
    animation: loader-logo-anim 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    display: none;
}
@keyframes loader-logo-anim {
    0% {
            opacity: 0; 
            width: 10px; height: 10px;
            left: 43%;top: 35%;
         }
    20% {
            border-radius: 50%;
            width: 250px; height: 250px;
            left: 43%;top: 25%;
            opacity: 80%;
            background-color: #ffffff;
    }
    100%   {
            opacity: 1; 
            width: 100%; height: 100%;
            left: 0%;top: 0%; 
            border-radius: 0%; 
            background-size: 0%;
         }
}


/* Display the content of sections temporarily */

.stage-click{
    width: 80%;
    height: 80%;
    margin: auto;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    color: #ffffff;
    animation: stageclickfadeanim 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
}
.stage-click img{
    width: 100%;
    height: auto;
    border-radius: 20px;
}
@keyframes stageclickfadeanim {
    from {opacity: 0;}
    to {opacity: 1;}
}

.returnBtn{
    width: 90px;
    height: 40px;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    font-size: 1.4em;
    color: #ffffff;
    background-color: transparent;
    border: none;
    padding-left: 24px;
    margin: 5px;
    background-image: url(cssimg/close.png);
    background-repeat: no-repeat;
    background-position: top 10px left;
    background-size: 20px;
    animation: testReturnBtn 1s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
    cursor:pointer;
    transition: color .3s , font-size .3s , right  .3s, padding-left .3s, margin .3s, background-image .3s, background-size .3s, background-position .3s;
}
@keyframes testReturnBtn {
    from {opacity: 0;}
    to {opacity: 1;}
}
.returnBtn:hover{
    font-size: 1.2em;
    color: #000000;
    padding-left: 0px;
    margin: 5px;
    background-image: url(cssimg/close2.png);
    background-position: top 12px left;
    background-size: 17px;
}
/*-----------------------Slider 1 - About me-----------------------*/

.slider-stage-data-1{
    position: relative;
    width: 100%;
    height: 100%;
    line-height: 20px;
    color: #ffffff;
}
.slider-stage-data-1 h2{
    position: absolute;
    font-size: 1.3em;
    right: 50px;
    opacity: 0;
    animation: stg-anime-tp-bt 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    z-index: 8;
}
@keyframes stg-anime-tp-bt {
    from {opacity: 0;top: -10px;}
    to {opacity: 1;top: 5px;}
}

.btn-about-list{
    position: absolute;
    bottom: 20px;
    text-align: left;
    color: #db7fcc;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 80%, rgba(0,0,0,0.8));
}

.btn-about-list button{
    position: relative;
    height: 0px;
    margin: 0 10px;
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    cursor:pointer;
    z-index: 18;
    font-size: 0.9em;
    color: #db7fcc;
    text-decoration: none;
    transition: color .3s , font-size .3s;
}

.btn-about-list button:hover{
    font-size: 1.3em;
    color: #ffffff;
    animation: btn-about-list-hover-anim 1.5s;
}



.resume-box-main{
    position: relative;
    width: 98%;
    height: 220px;
    text-align: center;
    vertical-align: middle;
}

.slider-all{
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom center ;
    background-size: 100%;
    border-radius: 7px;
    border: #ffffff solid 1px;
    box-shadow: 0px 0px 5px #000000; 
    z-index: 2;
    opacity: 0;
    -webkit-box-reflect: below 3px linear-gradient(to bottom, rgba(0,0,0,0.0), 95%, rgba(0,0,0,0.8));
}

.slider1-a{
    margin-left: 38.2%;
    width: 192px;
    height: 138px;
    background-image: url(cssimg/slider1/1.jpg);
    z-index: 6;
    animation: slider1-anim 0.4s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim{
    from {opacity: 0;margin-top: 0px}
    to {opacity: 1;margin-top: 43px}
}

.slider2-l{
    margin-top: 58px;
    width: 170px;
    height: 110px;
    background-image: url(cssimg/slider1/2l.jpg);
    z-index: 5;
    animation: slider1-anim-2-l 0.7s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-2-l{
    from {opacity: 0;margin-left: 35%;}
    to {opacity: 1;margin-left: 25.9%;}
}

.slider2-r{
    margin-top: 58px;
    width: 170px;
    height: 110px;
    background-image: url(cssimg/slider1/2r.jpg);
    z-index: 5;
    animation: slider1-anim-2-r 0.7s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-2-r{
    from {opacity: 0;margin-left: 43%}
    to {opacity: 1;margin-left: 53.5%}
}
.slider3-l{
    margin-top: 68px;
    margin-left: 16.5%;
    width: 137px;
    height: 93px;
    background-image: url(cssimg/slider1/3l.jpg);
    z-index: 4;
    animation: slider1-anim-3-l 0.9s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-3-l{
    from {opacity: 0;margin-left: 28%;}
    to {opacity: 1;margin-left: 16.5%}
}
.slider3-r{
    margin-top: 68px;
    margin-left: 67.4%;
    width: 137px;
    height: 93px;
    background-image: url(cssimg/slider1/3r.jpg);
    z-index: 4;
    animation: slider1-anim-3-r 0.9s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-3-r{
    from {opacity: 0;margin-left: 56%;}
    to {opacity: 1;margin-left: 67.4%}
}

.slider4-l{
    margin-top: 76px;
    margin-left: 8%;
    width: 113px;
    height: 75px;
    background-image: url(cssimg/slider1/4l.jpg);
    z-index: 3;
    animation: slider1-anim-4-l 1.1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-4-l{
    from {opacity: 0;margin-left: 19%;}
    to {opacity: 1;margin-left: 8%}
}
.slider4-r{
    margin-top: 76px;
    margin-left: 79%;
    width: 113px;
    height: 75px;
    background-image: url(cssimg/slider1/4r.jpg);
    z-index: 3;
    animation: slider1-anim-4-r 1.1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-4-r{
    from {opacity: 0;margin-left: 69%}
    to {opacity: 1;margin-left: 79%}
}


.slider5-l{
    margin-top: 85px;
    margin-left: 1%;
    width: 90px;
    height: 60px;
    background-image: url(cssimg/slider1/5l.jpg);
    z-index: 2;
    animation: slider1-anim-5-l 1.5s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-5-l{
    from {opacity: 0;margin-left: 11%}
    to {opacity: 1;margin-left: 1%}
}
.slider5-r{
    margin-top: 85px;
    margin-left: 89%;
    width: 90px;
    height: 60px;
    background-image: url(cssimg/slider1/5r.jpg);
    z-index: 2;
    animation: slider1-anim-5-r 1.5s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
}
@keyframes slider1-anim-5-r{
    from {opacity: 0;margin-left: 79%}
    to {opacity: 1;margin-left: 89%}
}

.slider1BR{
    bottom: 30px;
    left: 37%;
    width: 22%; 
    margin: 40px 0 0 60px;
}


.slider1BL{
    bottom: 50%;
    left: 30%;
    width: 19%; 
    margin: 40px 60px 0 0;
}


/*-----------------------Slider 2 - Web Design-----------------------*/
.slider-stage-data-2{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #b6050f;
    overflow: hidden;
}

.link-webdesign{
    position: relative;
    height: 50px;
    z-index: 5;
}

.link-webdesign button{
    position: absolute;
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    cursor:pointer;
    right: 118px;
    top: 5px;
    font-size: 1.2em;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    z-index: 8;
    padding-top: 20px;
    background-image: url(cssimg/arrowiconlink2.png);
    background-repeat: no-repeat;
    background-position: top 3px center;
    background-size: 15px;
    animation: link-webdesign-anim 3s;
    animation-iteration-count: infinite;
    opacity: 0.3;
    transition: color .3s , font-size .3s , right  .3s, background-image .3s, background-position .3s;
}

@keyframes link-webdesign-anim {
    0% {opacity: 0.2;}
    50% {opacity: 0.5;}
    100% {opacity: 0.2;}
}

.link-webdesign button:hover{
    font-size: 1.15em;
    color: #000000;
    right: 118px;
    background-image: url(cssimg/arrowiconlink2over.png);
    background-position: top 8px center;
}



.monitor-graphic{
    position: absolute;
    right:0px;
    bottom: 0px;
    width: 450px;
    height: 100%;
    background-image: url(cssimg/webresdesign.png);
    background-repeat: no-repeat;
    background-position: bottom left ;
    background-size: 400px;
    z-index: 3;
    animation: monitor-graphic-anim 2s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes monitor-graphic-anim {
    from {opacity: 0;}
    to {opacity: 1;}
}


/* Fading animation */
.fadeinstyle {
    animation: fade-style-anim 3s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes fade-style-anim {
    from {opacity: 0} 
    to {opacity: 0.7}
}
.fadeoutstyle {
    animation: fade-style-an-out 1s;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
}
@keyframes fade-style-an-out {
    from {opacity: 0.7} 
    to {opacity: 0}
}

.mySlides{
    display: none;
}



.monitor-graphic-photo1{
    background-image: url(cssimg/webresdesignon1.png);
    background-repeat: no-repeat;
    background-position: bottom left ;
    background-size: 400px;
    position: absolute;
    right:0px;
    bottom: 0px;
    width: 450px;
    height: 100%;
    z-index: 4;
    opacity: 0;
}

.monitor-graphic-photo2{
    background-image: url(cssimg/webresdesignon2.png);
    background-repeat: no-repeat;
    background-position: bottom left ;
    background-size: 400px;
    position: absolute;
    right:0px;
    bottom: 0px;
    width: 450px;
    height: 100%;
    z-index: 4;
    opacity: 0;
}

.monitor-graphic-photo3{
    background-image: url(cssimg/webresdesignon3.png);
    background-repeat: no-repeat;
    background-position: bottom left ;
    background-size: 400px;
    position: absolute;
    right:0px;
    bottom: 0px;
    width: 450px;
    height: 100%;
    z-index: 4;
    opacity: 0;
}




.bigcircle{
    position: absolute;
    top: -650px;
    right: -400px;
    width: 1000px;
    height: 1000px;
    background-color: #e40613;
    border-radius: 50%;
    z-index: 2;
    animation: bigcircleanim 1.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes bigcircleanim {
    from {top: -950px}
    to {top: -650px}
}

.smallcircle{
    position: absolute;
    top: 100px;
    left: -200px;
    width: 500px;
    height: 500px;
    background-color: #760109;
    border-radius: 50%;
    z-index: 1;
    animation: smallcircleanim 1.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes smallcircleanim {
    from {top: 300px}
    to {top: 100px}
}

.itemBox{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 300px;
    height: 130px;
    color: #fb555f;
    font-size: 1em;
    line-height: 1.3em;
    text-decoration: none;
    z-index: 5;
}
.itemBoxText{
    position: relative;
    animation: itemfadeanin 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes itemfadeanin {
    from {opacity: 0; padding: 2px 5px 2px 10px ;}
    to {opacity: 1; padding: 2px 5px 2px 30px ;}
}

.bulletcolor{
    position: absolute;
    left: 0px;
    top: 5px;
    width: 25px;
    height: 15px;
}




/*-----------------------Slider 3 - Illustration - Cartoon-----------------------*/
.slider-stage-data-3{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: url(cssimg/slider3/ehsan-bazaei-cartoon.jpg);
    background-repeat: no-repeat;
    background-position: bottom left ;
    animation: newspaperanim 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes newspaperanim {
    from {opacity: 0; background-size: 200px}
    to {opacity: 1; background-size: 285px}
}

.cartoonItem{
    position: absolute;
    bottom: 50px;
    right: 120px;
    width: 30%;
    max-width: 230px;
    height: auto;
    animation: cartoonItemanim 1.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 65%, rgba(0,0,0,0.5));

}
@keyframes cartoonItemanim {
    from {opacity: 0; right: 220px;}
    to {opacity: 1; right: 120px}
}
.cartoonlogo{
    position: absolute;
    height: auto;
    animation: cartoonlogoanim 1.5s;
    animation-delay: 1.0s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes cartoonlogoanim {
    from {opacity: 0; max-width: 320px; width:320px; right: 0%; bottom: 0%;}
    to {opacity: 1; max-width: 120px; width: 120px;right: 5%; bottom: 41%;}
}


.link-cartoon{
    position: relative;
    height: 50px;
    z-index: 5;
    animation: link-cartoon-anim 1s;
    animation-delay: 2.0s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes link-cartoon-anim {
    from {opacity: 0;margin-top: 10px;}
    to {opacity: 1;margin-top: 0px;}
}

.link-cartoon button{
    position: absolute;
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    cursor:pointer;
    right: 125px;
    top: 97px;
    font-size: 1.25em;
    letter-spacing: 0px;
    text-decoration: none;
    color: #8b0e01;
    z-index: 8;
    padding-top: 20px;
    background-image: url(cssimg/arrowiconlink3over.png);
    background-repeat: no-repeat;
    background-position: top 3px center;
    background-size: 15px;
    animation: link-cartoon-anim-a 3s;
    animation-iteration-count: infinite;
    opacity: 0.3;
    transition: color .3s , font-size .3s , right  .3s, background-image .3s, background-position .3s;
}
@keyframes link-cartoon-anim-a {
    0% {opacity: 0.2;}
    50% {opacity: 0.5;}
    100% {opacity: 0.2;}
}
.link-cartoon button:hover{
    font-size: 1.3em;
    color: #ffffff;
    right: 120px;
    background-image: url(cssimg/arrowiconlink2.png);
    background-position: top 8px center;
}


/*-----------------------Slider 4 - Graphic-----------------------*/

.slider-stage-data-4{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #96979b;
    overflow: hidden;
}

.slider-stage-data-4::before {
    content: "";
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-image: url(cssimg/slider4/handsR.png), url(cssimg/slider4/handsL.png);
    background-position: center center;
    background-repeat: no-repeat;  
    background-size: 740px;
    animation: bgHanAnim 2.5s;
    animation-delay: 1.3s;
    animation-fill-mode: forwards;
    z-index: 2;
}
@keyframes bgHanAnim{
    from {opacity: 0;background-size: 1200px; background-position:  100% 100% ,  0% 0%;}
    to {opacity: 1; background-size: 740px;background-position: center center, center center;}
}

.Lightbg{
    width: 100%;
    height: 100%;
    background-image: url(cssimg/slider4/lightbg.jpg);
    background-size: 380px;
    background-repeat: no-repeat;
    background-position: center center ;
    animation: LightbgAnime 2s;
    animation-delay: 3.0s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes LightbgAnime{
    from {opacity: 0;}
    to {opacity: 1; }
}

.handLight{
    position: relative;
    width: 280px;
    height: 580px;
    margin: auto;
    background-image: url(cssimg/slider4/lighthand.png);
    background-size: 280px;
    background-repeat: no-repeat;
    background-position: center center ;
    animation: handLightAnim 5s  linear 0s infinite;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes handLightAnim {
    0%   {opacity: 0.1;transform: rotate(0deg);background-size: 350px;}
    25%  {opacity: 0.8;transform: rotate(90deg);}
    50%  {opacity: 1.0;transform: rotate(180deg);background-size: 280px;}
    75%  {opacity: 0.8;transform: rotate(270deg);}
    100% {opacity: 0.1;transform: rotate(360deg);background-size: 350px;}
}

.SquareR{
    position: absolute;
    top: -120px;
    right: -180px;
    width: 400px;
    height: 400px;
    background-color: #ffde00;
    border-radius: 14%;
    transform: rotate(45deg);
    z-index: 2;
    animation: SquareRanim 1.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes SquareRanim{
    from {opacity: 0;right: -280px;top: -250px;}
    to {opacity: 1; right: -180px;top: -120px;}
}

.SquareL{
    position: absolute;
    top: -30px;
    left: -180px;
    width: 400px;
    height: 400px;
    background-color: #554f4f;
    border-radius: 14%;
    transform: rotate(45deg);
    z-index: 2;
    animation: SquareLanim 1.5s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes SquareLanim{
    from {opacity: 0;left: -380px;top: 150px;}
    to {opacity: 1; left: -180px;top: -30px;}
}

.itemBox2{
    position: absolute;
    bottom: 70px;
    left: 0px;
    width: 300px;
    height: 170px;
    color: #96979b;
    font-size: 1.1em;
    line-height: 1.4em;
    text-decoration: none;
    z-index: 5;
}

.item2Plek{
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    font-weight: bold;
    cursor:pointer;
    letter-spacing: 0px;
    z-index: 8;
    text-transform: uppercase;
    margin-left: 10px;
    color: #96979b;
    font-size: 1em;
    line-height: 1.2em;
    text-decoration: none;
    transition: color .3s , margin-left  .3s;
}

.item2Plek:hover{
    margin-left: 20px;
    color: #ffffff;
}


.itemBoxText2{
    position: relative;
    animation: itemfadeanin2 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes itemfadeanin2 {
    from {opacity: 0; padding: 2px 5px 2px 10px ;}
    to {opacity: 1; padding: 2px 5px 2px 30px ;}
}

.bulletcolor2{
    position: absolute;
    left: 0px;
    top: 6px;
    width: 40px;
    height: 17px;

}




.s4logotext{
    position: absolute;
    top: 10px;
    right: 40px;
    width: 150px;
    height: 195px;
    text-align: center;
    padding: 10px 0 0 0;
    z-index: 3;
    transform: rotate(315deg);
    background-image: url(cssimg/slider4/logo.png);
    background-repeat: no-repeat;
    background-size: 120px;
    animation: grRightAnim 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes grRightAnim {
    from {opacity: 0;  background-position: center 60px; }
    to {opacity: 1;  background-position: center 120px; }
}

.s4logotext button{
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    font-weight: bold;
    cursor:pointer;
    letter-spacing: 0px;
    z-index: 8;
    font-size: 1.6em;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    line-height: 1em;
    margin: 1px;
    animation: s4logotext-anim-a 3s;
    animation-iteration-count: infinite;
    opacity: 0.3;
    transition: color .3s , font-size .3s;
}
@keyframes s4logotext-anim-a {
    0% {opacity: 0.4;}
    50% {opacity: 1;}
    100% {opacity: 0.4;}
}
.s4logotext button:hover{
    font-size: 1.8em;
    color: #000000;
}


.s4logotext h3{
    font-size: 2em;
    text-transform: uppercase;
    color: #96979b;
    line-height: 1em;
    margin: 1px;
    animation: grRightTextAnim 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}
.s4logotext h4{
    font-size: 2.5em;
    text-transform: uppercase;
    color: #fff;
    line-height: 1em;
    margin: 1px;
    animation: grRightTextAnim 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes grRightTextAnim {
    from {opacity: 0; }
    to {opacity: 1;  }
}


/*-----------------------Slider 5 - Multimedia-----------------------*/

.slider-stage-data-5{
    position: relative;
    width: 100%;
    height: 100%;
    line-height: 20px;
    color: #ffffff;
    overflow: hidden;
}

.mmtitle{
    position: absolute;
    right: 0;
    height: 50px;
    z-index: 5;
    text-align: right;
    text-transform: uppercase;
    font-size: 1.3em;
    animation: link-mmtitle-anim 1s;
    animation-delay: 2.0s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes link-mmtitle-anim {
    from {opacity: 0; padding: 50px 20px 20px 20px;}
    to {opacity: 1;padding: 20px 20px 20px 20px;}
}
.mmtitle button{
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    font-size: 1.0em;
    text-transform: uppercase;
    font-weight: bold;
    cursor:pointer;
    letter-spacing: 0px;
    z-index: 8;
    text-decoration: none;
    color: #c1e956;
    margin-right: 20px;
    animation: stg-anime-tp-bt-MM 3s;
    animation-iteration-count: infinite;
    opacity: 0.3;
    transition: color .3s , letter-spacing  .5s, margin-right .5s;
}
@keyframes stg-anime-tp-bt-MM {
0% {opacity: 0.6;}
50% {opacity: 1;}
100% {opacity: 0.6;}
}

.mmtitle button:hover{
    color: #7b9e1a;
    letter-spacing: 3px;
    right: 20px;
    margin-right: 0px;
}

.textMM{
    position: absolute;
    left: 10px;
    right: 10px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
    color: #293509;
    text-align: center;
    font-size: 0.9em;
    padding: 10px;
    bottom: 10px;
    opacity: 0;
    animation: stg-anime-bt-tp-MM 2s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
    z-index: 8;
}
@keyframes stg-anime-bt-tp-MM {
    from {opacity: 0; letter-spacing: 0.1em; }
    to {opacity: 0.6; letter-spacing: 0.2em;}
}


.mmBoxMain{
    position: relative;
    width: 98%;
    height: 220px;
    text-align: center;
    vertical-align: middle;
}

.sliderallMM{
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom center ;
    background-size: 100%;
    border-radius: 0px 20px ;
    border: #ffffff solid 1px;
    box-shadow: 0px 0px 5px #000000; 
    z-index: 2;
    opacity: 0;
    -webkit-box-reflect: below 2px linear-gradient(to bottom, rgba(241, 229, 229, 0), 96%, rgba(0,0,0,0.8));
}

.Mslider1a{
    margin-left: 38.2%;
    width: 192px;
    height: 138px;
    background-image: url(cssimg/slider1/m1.jpg);
    z-index: 6;
    animation: Mslider1anim 1.0s;
    animation-delay: 2.2s;
    animation-fill-mode: forwards;
}
@keyframes  Mslider1anim {
    from {opacity: 0; margin-top: 60px;}
    to {opacity: 1; margin-top: 43px;}
}

.Mslider2l{
    margin-top: 58px;
    width: 170px;
    height: 110px;
    background-image: url(cssimg/slider1/m2l.jpg);
    z-index: 5;
    animation: slider1-anim-2-l 1.0s;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
}


.Mslider2r{
    margin-top: 58px;
    width: 170px;
    height: 110px;
    background-image: url(cssimg/slider1/m2r.jpg);
    z-index: 5;
    animation: slider1-anim-2-r 1.0s;
    animation-delay: 1.8s;
    animation-fill-mode: forwards;
}

.Mslider3l{
    margin-top: 68px;
    margin-left: 16.5%;
    width: 137px;
    height: 93px;
    background-image: url(cssimg/slider1/m3l.jpg);
    z-index: 4;
    animation: slider1-anim-3-l 0.8s;
    animation-delay: 1.4s;
    animation-fill-mode: forwards;
}

.Mslider3r{
    margin-top: 68px;
    margin-left: 67.4%;
    width: 137px;
    height: 93px;
    background-image: url(cssimg/slider1/m3r.jpg);
    z-index: 4;
    animation: slider1-anim-3-r 0.8s;
    animation-delay: 1.4s;
    animation-fill-mode: forwards;
}

.Mslider4l{
    margin-top: 76px;
    margin-left: 8%;
    width: 113px;
    height: 75px;
    background-image: url(cssimg/slider1/m4l.jpg);
    z-index: 3;
    animation: slider1-anim-4-l 0.6s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}

.Mslider4r{
    margin-top: 76px;
    margin-left: 79%;
    width: 113px;
    height: 75px;
    background-image: url(cssimg/slider1/m4r.jpg);
    z-index: 3;
    animation: slider1-anim-4-r 0.6s;
    animation-delay: 1.2s;
    animation-fill-mode: forwards;
}


.Mslider5l{
    margin-top: 85px;
    margin-left: 1%;
    width: 90px;
    height: 60px;
    background-image: url(cssimg/slider1/m5l.jpg);
    z-index: 2;
    animation: slider1-anim-5-l 0.4s;
    animation-delay: 1.0s;
    animation-fill-mode: forwards;
}

.Mslider5r{
    margin-top: 85px;
    margin-left: 89%;
    width: 90px;
    height: 60px;
    background-image: url(cssimg/slider1/m5r.jpg);
    z-index: 2;
    animation: slider1-anim-5-r 0.4s;
    animation-delay: 1.0s;
    animation-fill-mode: forwards;
}

.dvdl {
    position: absolute;
    top: -280px;
    left: -250px;
    width: 210px;
    height: 210px;
    border: #7b9e1a 180px solid;
    border-radius: 50% ;
    background-position: center center ;
    animation: dvdlanime 2s;
    animation-delay: 0s;
}
@keyframes dvdlanime {
    from { opacity: 0; width: 10px; height: 10px; border: #7b9e1a 300px solid; }
    to { opacity: 1; }
}


.dvdr {
    position: absolute;
    top: 85px;
    right: -100px;
    width: 70px;
    height: 70px;
    border: #abd53b 90px solid;
    border-radius: 50%;
    animation: dvdranime 2s;
    animation-delay: 0s;
}
@keyframes dvdranime {
    from { opacity: 0; width: 10px; height: 10px; border: #abd53b 160px solid; }
    to { opacity: 1; }
}

/*-----------------------Slider 6 - Character-----------------------*/

.slider-stage-data-6{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.character-link{
    position: absolute;
    top: 0px;
    height: 125px;
    width: 400px;
    z-index: 2;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 95%, rgba(47, 225, 154, 0.8));
    animation: character-link-anim 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes character-link-anim {
    from { opacity: 0; left: 200px; }
    to { opacity: 1; left: 228px;}
}


.character-link button{
    position: absolute;
    top: 97px;
    border: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    cursor:pointer;
    background: linear-gradient(to right, #951b81 , #e30613 , #ffde00 , #95c11f , #0069b4);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-decoration: none;
    transition: color .3s , letter-spacing  .5s, margin-right .5s;
}
.character-link button:hover{
    letter-spacing: 3px;
}


.characterBoxText{
    position: absolute;
    right: 5px;
    top: 2px;
    width: 200px;
    height: 250px;
    z-index: 5;
}

.characterBoxText img{
    position: absolute;
    right: 15px;
    top: 10px;
    width: 90px;
    height: 90px;
    animation: characterLogo 0.3s  linear ;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}
@keyframes characterLogo {
    from   {opacity: 0; top: 40px;right: 40px;width: 40px;}
    to {opacity: 1; top: 10px;right: 15px;width: 90px;}
}

.characterBoxText h2{
    position: absolute;
    top: 83px;
    right: 20px;
    font-size: 2em;
    line-height: 0px;
    color: #009640;
    letter-spacing: -0.05em;
    opacity: 0;
    animation: characterBoxTextH2 0.5s  linear ;
    animation-delay: 0.9s;
    animation-fill-mode: forwards;
}
@keyframes characterBoxTextH2 {
    from   {opacity: 0; right: -10px;}
    to {opacity: 1; right: 20px;}
}

.characterBoxText h3{
    position: absolute;
    top: 89px;
    right: 20px;
    font-size: 3.35em;
    line-height: 0px;
    font-weight: bolder;
    color: #ffffff;
    letter-spacing: -0.05em;
    opacity: 0;
    animation: characterBoxTextWhiteAnim 0.5s  linear ;
    animation-delay: 0.9s;
    animation-fill-mode: forwards;
}
@keyframes characterBoxTextWhiteAnim {
    from   {opacity: 0; right: 60px;}
    to {opacity: 0.8; right: 20px;}
}


.characterBoxText h4{
    position: absolute;
    top: 145px;
    right: 20px;
    font-size: 1.72em;
    line-height: 0px;
    font-weight: bolder;
    color: #c9fd2a;
    letter-spacing: -0.05em;
    opacity: 0;
    animation: characterBoxTextH4 0.3s  linear ;
    animation-delay: 1.3s;
    animation-fill-mode: forwards;
}
@keyframes characterBoxTextH4 {
    from   {opacity: 0; top: 125px;}
    to {opacity: 0.8; top: 145px;}
}

.chWitBg{
    position: absolute;
    top: 0px;
    width: 110%;
    height: 125px;
    background-color: #ffffff;
    opacity: 0.8;
    animation: chWitBgAnim 0.7s  linear ;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes chWitBgAnim {
0%   {opacity: 0; height: 30px;}
40%  {opacity: 1; height: 165px;}
100% {opacity: 0.8; height: 125px;}
}

.characters{
    position: absolute;
    left: 15px;
    top: 20px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    background-color: #a2b173;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    opacity: 0;
    animation: charactersSliderBox 1s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    z-index: 3;
}
@keyframes charactersSliderBox {
    from   {opacity: 0; width: 10px;height: 10px;top: 90px;left: 85px;}
    to {opacity: 1; width: 200px;height: 200px;top: 20px;left: 15px;}
}

.characters img{
    width: 200px;
    height: 200px;
}

.sliderCH{
    animation: animSliderCH 3s;
    animation-fill-mode: forwards;
}

@keyframes animSliderCH {
    from   {opacity: 0.0;}
    to {opacity: 1.0;}
}

/* Ball */
canvas{
    width: 100%;
    height: 100%;
}


/*-----------------------Slider 7 - Painting-----------------------*/

.paintBox{
    overflow: hidden;
}

.paintBox img{
    position: absolute;
    margin-left: 10%;
    margin-top: 60px;
    width: 80%;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 85%, rgba(0,0,0,0.5));
    animation: paintAnim 3s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
}
@keyframes paintAnim {
    from   {opacity: 0.0; width: 30%;margin-left: 40%; margin-top: 100px;}
    to {}
}


.paint-list{
    position: absolute;
    width: 100%;
    bottom: 20px;
    color: #05d9f9;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 80%, rgba(0,0,0,0.8));
}

.paint-list button{
    border: none;
    background: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    cursor:pointer;
    letter-spacing: 0px;
    z-index: 8;
    font-size: 0.9em;
    color: #05d9f9;
    text-decoration: none;
    margin: 0px  5px;
    transition: color .3s , font-size .3s;
}

.paint-list button:hover{
    font-size: 1.3em;
    color: #ffffff;
    animation: btn-about-list-hover-anim 1.5s;
}




/* Slider 0 - Main*/
.logo-cadr{ 
    flex: 60%;
    height: 250px;
    background-color: #ffffff;
    background-image: url(cssimg/ehsanbazaeilogo.svg);
    background-repeat: no-repeat;
    background-position: center left 50px ;
    background-size: 305px;
    position: relative;
    box-shadow: 5px 0 3px -3px #00000080;
    animation: logo-anim 3s;
    animation-delay: 0s;
}
@keyframes logo-anim {
    from {background-size: 700px;  }
    to { background-size: 305px;  }
}




.portrait{
    background-image: url(cssimg/ehsanbazaei.jpg);
    background-repeat: no-repeat;
    background-position: center left 50px ; 
    background-size: 250px;
    width: 300px;
    height: 250px;
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0; 
    animation: portrait-fade 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}
@keyframes portrait-fade {
    from {opacity: 0;   }
    to {opacity: 1;  background-image: url(cssimg/ehsanbazaei.jpg); }
}

.menubox{
    flex: 38%;
    min-height: 80px;
    padding: 0 0 0 25px;
    transition: 1s;
}

ul {
    list-style-type: none;
    margin: 2px 0 0 0;
    padding: 0;
    background-color: transparent;
  }
  
li a{
    display: block;
    font-size: 1.0em;
    text-transform: uppercase;
    color: #939393;
    padding: 5px 0px 5px 0px;
    position: relative;
    margin: 1px 1px 1px -15px ;
    cursor: pointer;
    letter-spacing: 0.3em;
    text-decoration: none; 
    transition: all .5s;
}

li a::first-letter{
    font-size: 1.1em;
    letter-spacing: 0.7em;
    font-weight: bold;
    color: #ffffff;
    line-height: 0px;
}

li a:before,
li a:after{
  content: '';
  position: absolute;
  left: 22px;    /* background-color-size */
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
  transform: translate3D(0,-100%,0); 
  transition: all .5s;
}



li:nth-child(1) a:after{
    background-color: #951b81;
}
li:nth-child(2) a:after{
    background-color: #e30613;
}
li:nth-child(3) a:after{
    background-color: #f39200;
}
li:nth-child(4) a:after{
    background-color: #ffde00;
}
li:nth-child(5) a:after{
    background-color: #95c11f;
}
li:nth-child(6) a:after{
    background-color: #009640;
}
li:nth-child(7) a:after{
    background-color: #0069b4;
}
 
li a:hover{
    color: white;
}
  
/* //// Slide Left  //// */

li a:after{
    top: 0;
    border-radius: 10px;
    z-index: -1;
    transform: translate3D(-101%,0px,0px);
  }
  
  li a:hover:after{
    transform: translate3D(-50px,0px,0px);
    transition: all .5s;
  }




footer {
    font-family: Arial, sans-serif;
    text-transform:none;
    padding: 0px;
    margin: 10px 0 0 10px ;
    color: #999;
    font-size: 0.85em;
    line-height: 2em;
    font-weight:lighter;
}

footer a{
    color: #999;
    font-weight:bold;
    margin: 0 0 0 5px;
    text-decoration: none;
    transition: all 0.5s ease-out;
}
footer a:hover{
    font-size: 1em;
    letter-spacing: 0.3em;
    
}

/* Reuse 1 class another class*/
.gradient, footer a:hover{   
    background: linear-gradient(to right, #951b81 , #e30613 , #ffde00 , #95c11f , #0069b4);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

/* Anime */

@keyframes fade-opacity-1 {
    from {opacity: 0;}
    to {opacity: 1;}
}

/*---------------------- Public in Entries & Categories-----------------------*/

.btn-back{
    position: absolute;
    top: 10px;
    right: 10px;
    background:none;
    border: none;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 1em;
    letter-spacing: 1px;
    cursor:pointer;
    opacity: 50%;
    transition: color .3s , letter-spacing .3s ;
}


.btn-back:hover{
    opacity: 100%;
    letter-spacing: 3px;
}

/*-----------------------Responsive layout-----------------------*/
@media (max-width: 1220px) {
    .slider-stage-data-1{    width: 97%;}
}

@media (max-width: 1024px) {
    .logo-cadr, .menubox {  flex: 100%;}
    .box-main{     margin: 0px 0px 0px 0px;    transition: 1s; }
    .slider-stage-data-1{    width: 93%; }
}

@media (max-width: 660px) {
    .itemBox{   display: none; }
    .characterBoxText{    display: none; }
}

@media (max-width: 601px) {
    .portrait {  display:none;}
    .cartoonItem{ display:none;  }
    .cartoonlogo{  display:none;}
    .slider-stage-data-4::before {    display: none;}
    .slider-stage-data-1 h2{    display: none;}
    .handLight, .Lightbg{    display: none;}
    .slider-stage-data-5 h2{    display: none;}
    .textMM{ display: none;}
    .mmtitle button{font-size: 1.3em;margin-right: 40px;margin-top: 170px;color: #fff;}
    .link-cartoon button{ right: -40px;top: 120px;font-size: 1.25em;z-index: 8;padding-top: 20px;rotate: 270deg;}
    .link-cartoon button:hover{font-size: 2em;background-position: top 120px; right: -40px;font-size: 1.30em; color: #fff; opacity: 1;}
    .link-webdesign button{ right: 30px;}
    .link-webdesign button:hover{right: 30px;}

}


@media (max-width: 440px){
    .paint-list{    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 80%, rgba(0,0,0,0));}
}

@media (max-width: 341px){
    .s4logotext{  display: none;  }
    .SquareR{    display: none;}
}

@media (min-height: 768px){
    .box-main{  margin: 20% 0px 0px 0px;}
}


@media (min-width: 1910px){
    .box-main{  margin: 20% 0px 0px 0px;}
    .logo-cadr{ height: 355px;background-position: center left 10px ;background-size: 420px;}
    @keyframes logo-anim {from {background-size: 700px;  } to { background-size: 420px;  }}
    .portrait{ background-size: 350px;width: 400px; height: 360px; }
    .name-style {font-size: 4em; }
    .paintBox img{ -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), 80%, rgba(0,0,0,0));}
    .menubox{padding: 0 0 0 25px;}
    li a{ font-size: 1.65em;margin: 1px 1px 1px -17px ;}
    li a::first-letter{ font-size: 1.1em; letter-spacing: 0.3em;}
    li a:before, li a:after{  left: 35px; }

    /* Slider About me */
    .slider1-a{margin-left: 42.5%;width: 250px;height: 165px;}
    @keyframes slider1-anim{from {opacity: 0;margin-top: 0px} to {opacity: 1;margin-top: 53px}}
    .slider2-l{margin-top: 78px;}
    @keyframes slider1-anim-2-l{from {opacity: 0;margin-left: 47%;}  to {opacity: 1;margin-left: 32.5%;}}
    .slider2-r{margin-top: 78px;}
    @keyframes slider1-anim-2-r{ from {opacity: 0;margin-left: 43%} to {opacity: 1;margin-left: 57%}}
    .slider3-l{   margin-top: 88px;}
    @keyframes slider1-anim-3-l{ from {opacity: 0;margin-left: 40%;} to {opacity: 1;margin-left: 24.3%}}
    .slider3-r{  margin-top: 88px;}
    @keyframes slider1-anim-3-r{  from {opacity: 0;margin-left: 51%;}  to {opacity: 1;margin-left: 67%}} 
    .slider4-l{  margin-top: 96px;}
    @keyframes slider1-anim-4-l{  from {opacity: 0;margin-left: 32%;}  to {opacity: 1;margin-left: 17.5%}}
    .slider4-r{ margin-top: 96px;}
    @keyframes slider1-anim-4-r{  from {opacity: 0;margin-left: 60%} to {opacity: 1;margin-left: 75%} }
    .slider5-l{  margin-top: 105px;}
    @keyframes slider1-anim-5-l{  from {opacity: 0;margin-left: 32%}  to {opacity: 1;margin-left: 12%}}
    .slider5-r{ margin-top: 105px;}
    @keyframes slider1-anim-5-r{  from {opacity: 0;margin-left: 60%}  to {opacity: 1;margin-left: 82%}}
    .btn-about-list button{  font-size: 1.7em;  }
    /* Multimedia */
    .textMM{   font-size: 1.5em;}
    .mmtitle{ font-size: 2em;}
    .dvdr {border: #abd53b 170px solid;top: 80px;right: -240px;width: 150px;height: 150px; }
    .dvdl {  top: -220px;border: #7b9e1a 280px solid;}
    .Mslider1a{    margin-left: 45%;}
    @keyframes  Mslider1anim {    from {opacity: 0; margin-top: 100px;}    to {opacity: 1; margin-top: 63px;}}
    .Mslider2l{   margin-top: 78px; }    
    .Mslider2r{   margin-top: 78px;}
    .Mslider3l{     margin-top: 88px; }
    .Mslider3r{     margin-top: 88px; } 
    .Mslider4l{     margin-top: 96px; }
    .Mslider4r{     margin-top: 96px; }
    .Mslider5l{    margin-top: 105px; }
    .Mslider5r{     margin-top: 105px; }
    
    /* Graphic */
    @keyframes bgHanAnim{   from {opacity: 0;background-size: 1400px; background-position:  100% 100% ,  0% 0%;}   to {opacity: 1; background-size: 1050px;background-position: center center, center center;} }
    .handLight{   height: 380px;  }  
    .SquareR{    width: 500px;    height: 500px;}
    .SquareL{ width: 500px;   height: 500px;}
    .s4logotext{right: 40px; width: 220px;height: 395px; background-size: 200px;}
    @keyframes grRightAnim {from {opacity: 0;  background-position: center 60px; }to {opacity: 1;  background-position: center 180px; }}
    .s4logotext button{font-size: 2.6em; line-height: 1em; margin: 1px;}
    .s4logotext button:hover{font-size: 2.8em;color: #000000;}
    .s4logotext h3{font-size: 3em;line-height: 1em;margin: 1px;}
    .s4logotext h4{font-size: 3.5em;line-height: 1em; margin: 1px;}
    .item2Plek{  font-size: 1.4em;  line-height: 0.2em;}

    /* Web design */
    .link-webdesign button{right: 60px;top: 5px;font-size: 2.2em;padding-top: 30px;background-size: 25px;}
    .link-webdesign button:hover{font-size: 2em;color: #000000;right: 60px;background-image: url(cssimg/arrowiconlink2over.png);background-position: top 20px center;}
    .itemBox{  height: 180px; font-size: 1.3em; line-height: 1.4em; }

    /* .cartoonItem */
    .cartoonItem{ max-width: 330px;}
    @keyframes newspaperanim {from {opacity: 0; background-size: 200px}to {opacity: 1; background-size: 385px}}
    .link-cartoon button{right: 125px; top: 5px;font-size: 2.25em;background-size: 25px;}
    .link-cartoon button:hover{font-size: 2em;background-position: top 20px center;}
    @keyframes cartoonlogoanim {from {opacity: 0; max-width: 420px; width:420px; right: 0%; bottom: 40%;} to {opacity: 1; max-width: 150px; width: 150px;right: 10%; bottom: 38%;}}

    /* paint */
    .paintBox img{margin-left: 20%;margin-top: 80px;width: 60%;}
    .paint-list button{font-size: 1.9em;margin: 0px  5px;}
    .paint-list{bottom: 40px;}
    .paint-list button:hover{ font-size: 3em;}

    /* character */
    .character-link{ top: -30px;height: 225px;width: 700px;}
    @keyframes character-link-anim { from { opacity: 0; left: 200px; }to { opacity: 1; left: 328px;}}
    .character-link button{ top: 167px;font-size: 3em;}
    @keyframes charactersSliderBox {from   {opacity: 0; width: 10px;height: 10px;top: 90px;left: 85px;}to {opacity: 1; width: 300px;height: 300px;top: 20px;left: 15px;}}
    .characters img{ width: 300px; height: 300px; }
    @keyframes chWitBgAnim {0%   {opacity: 0; height: 30px;}40%  {opacity: 1; height: 225px;}100% {opacity: 0.8; height: 190px;}}
    .characterBoxText{right: 5px;top: 2px;width: 300px;height: 350px;} 
    .characterBoxText img{right: 150px;top: 0px;width: 150px;height: 150px;}
    @keyframes characterLogo {from   {opacity: 0; top: 40px;right: 90px;width: 40px;} to {opacity: 1; top: 0px;right: 70px;width: 150px;}}
    .characterBoxText h2{top: 120px;right: 40px;font-size: 3em;}
    @keyframes characterBoxTextH2 {from   {opacity: 0; right: -10px;}to {opacity: 1; right: 40px;}}
    .characterBoxText h3{top: 135px;right: 40px;font-size: 5em;}
    @keyframes characterBoxTextWhiteAnim {from   {opacity: 0; right: 60px;}to {opacity: 0.8; right: 40px;}}
    .characterBoxText h4{top: 220px;right: 40px;font-size: 2.6em;}
    @keyframes characterBoxTextH4 {from   {opacity: 0; top: 125px;}to {opacity: 0.8; top: 220px;}}

    .btn-back{top: 10px;right: 50px;font-size: 2em;}
    footer { font-size: 1.3em;line-height: 2em;}
}

@media (min-width: 3000px){
    .paintBox img{margin-left: 25%;margin-top: 60px;width: 50%; }
    .paint-list button{font-size: 2.3em;margin: 0px  5px;}  
}