/* ---------- Public Css In Page ---------- */

.cat-stage{
    position: relative;
    background-color: #fff;
    color: #000000;
    width: 90%;
    height: 445px;
    margin: 50px 0 0 0;
    padding: 5px 0px 5px 20px ;
    border-radius: 0 25px 25px 0;
    animation: catfadeanim 2s;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes catfadeanim {
    from {opacity: 0; width: 70%;}
    to {opacity: 1; width: 90%;}
}


.color-box-left{
    position: absolute;
    left: 0px; top: 50px;
    height: 450px;
    width: 200px;
}
.color-box-left div{
    position: relative;
    width: 190px;
    height: 60px;
    margin-bottom: 6px;
    border: none;
}
.color-box-left button{
    position: absolute;
    left: -175px;
    width: 100%; height: 100%;
    padding-left: 20px;
    border: none;
    cursor: pointer;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    font-size: 0.9em;
    transition: left 2s;
    z-index: 1;
}
.color-box-left button:hover {
    left: 0px;
}



.color-box-left-inpage{
    position: absolute;
    left: 0px; top: 50px;
    height: 450px;
    width: 200px;
}
.color-box-left-inpage div{
    position: relative;
    width: 190px;
    height: 60px;
    margin-bottom: 6px;
    border: none;
}
.color-box-left-inpage button{
    position: absolute;
    left: -175px;
    width: 100%; height: 100%;
    padding-left: 20px;
    border: none;
    cursor: pointer;
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    font-size: 0.9em;
    transition: left 2s;
    z-index: 1;
}
.color-box-left-inpage button:hover {
    left: 0px;
}

.btnbg-1{background-color: #951b81; animation: btnfade 1s; animation-delay: 1.1s;animation-fill-mode: forwards;opacity: 0;}
.btnbg-2{background-color: #db0512; animation: btnfade 1s; animation-delay: 1.3s;animation-fill-mode: forwards;opacity: 0;}
.btnbg-3{background-color: #f39200; animation: btnfade 1s; animation-delay: 1.6s;animation-fill-mode: forwards;opacity: 0;}
.btnbg-4{background-color: #ffde00; animation: btnfade 1s; animation-delay: 1.9s;animation-fill-mode: forwards;opacity: 0;}
.btnbg-5{background-color: #95c11f; animation: btnfade 1s; animation-delay: 2.2s;animation-fill-mode: forwards;opacity: 0;}
.btnbg-6{background-color: #009640; animation: btnfade 1s; animation-delay: 2.5s;animation-fill-mode: forwards;opacity: 0;}
.btnbg-7{background-color: #0069b4; animation: btnfade 1s; animation-delay: 2.8s;animation-fill-mode: forwards;opacity: 0;}
@keyframes btnfade{
    from {opacity: 0;}
    to {opacity: 1;}
}

.cat-stage h1{
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    margin: 0px;
    font-size: 1.5em;
    background: linear-gradient(to right, #951b81 , #e30613 , #ffde00 , #95c11f , #0069b4);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}


.InPageFooter {
    color: #fff;
    opacity: 0.7;
}
.InPageFooter a{
    color: #ffffff;
}

/* ---------- Structure Semantic HTML InPage ---------- */

.main-stage{
    position: relative;
    display: flex;
    flex-direction: row;
    height: 390px;
    width: 93%;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.9em;
    line-height: 2em;
    text-transform: capitalize;
}

.article-cat-name{
    flex: 35%;
    overflow:auto;
    padding: 5px;
    border-right: #000000 solid 1px;
    text-transform: none;
    font-size: 1em;
    line-height: 1.5em;
}
.section-cat-name{
    flex: 65%;
    overflow:auto;
    padding: 5px;
    text-transform: none;
}
.aside-cat-name{
    position: absolute;
    top: 200px;
    right: -178px;
    width: 400px;
    height: 50px;
    font-size: 2.5em;
    text-align: center;
    font-weight: bold;
    letter-spacing: -3px;
    rotate: 270deg; 
}

.article-cat-name-about{
    flex: 35%;
    overflow:hidden;
    padding: 5px;
    border-right: #000000 solid 1px;
    text-transform: none;
}
/* ---------- Json Css ---------- */

.section-contin{
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
}
.section-contin div{
    width: 11%;
}
.section-entry{
    border: #000000 1px solid;
    margin: 1.7% ;
    padding: 2% 2% 1% 2% ;
    background-color: #f1f1f1;
    width: 25%;
}


.section-img{
    width: 100%;
    height: auto;

}
.article-img{
    width: 97%;
    height: auto;
}
.article-img-about{
    width: 97%;
    height: auto;
}

.section-contin-right{
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
}


/* ---------- Entry Page Css ---------- */

.entry-cat-stage{
    position: relative;
    background-color: #fff;
    color: #000000;
    width: 90%;
    min-height: 400px;
    margin: 50px 0 0 0;
    padding: 5px 0px 5px 20px ;
    border-radius: 0 25px 25px 0;
    animation: catfadeanim 2s;
    animation-delay: 0.7s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.entry-cat-stage h1{
    font-family: "Oswald", Impact, Charcoal, Arial, sans-serif;
    text-transform: uppercase;
    margin: 0px;
    font-size: 1.5em;
    background: linear-gradient(to right, #951b81 , #e30613 , #ffde00 , #95c11f , #0069b4);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.entry-stage{
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 390px;
    width: 93%;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.9em;
    line-height: 1em;
    text-transform: capitalize;
}

/* left stage */
.left-stage{
    flex: 30%;
    padding: 5px;
    border-left: #000000 solid 1px;    
}

.right-stage{
    flex: 70%;
    padding: 5px 2%;
    text-align: justify;
    line-height: 1.5em;
    text-transform: none;
}

.div-free{
    color: #555555;
    text-align: left;
    font-size: 1em;
}

.right-stage img{
    width: 99%;
    float: left;
    padding: 0 5px 5px 0;
}

.entry-aside-cat-name{
    position: absolute;
    top: 200px;
    right: -183px;
    width: 400px;
    font-size: 2.5em;
    text-align: center;
    font-weight: bold;
    letter-spacing: -3px;
    rotate: 270deg; 
}
/* box gallery */
.entry-section-contin{
    width: 99%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
}
/* link gallery row */
.entry-section-contin div{
    width: 22%;
}
.entry-section-contin img{
    width: 99%;
}



/* About Menu */

input[type=radio] {
    -webkit-appearance: none;
        -moz-appearance: none;
            appearance: none;
    position: fixed;
    top: -100vh;
    left: -100vh;
}
input:checked + label {
    color: #942681;
}
input[type=radio] ~ div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s;
}
input[type=radio]:checked ~ div {
    max-height: 800px;
}

.section-contin-accordion h2{
    font-size: 1.1em;
    line-height: 1em;
    cursor: pointer;
    transition: color 1s;
    margin: 5px 5px ;
}  
.section-contin-accordion h2:hover{
    color: #942681;
}
.data-accordion{
    width: 97%;
    margin: 5px 5px 10px 5px ;
    font-size: 0.9em;
    line-height: 1.5em;
    color: #555;
    text-align: justify;
}
.section-contin-accordion{
    width: 99%;

}
.hilight-btn{
    color: #942681;
}


/* Responsive */


@media (min-width: 800px) {
    .article-cat-name-about-vartical{
        display:none;
    }
}

@media (max-width: 800px) {
    .right-stage img{
        width: 99%;
        padding: 0% ;
    }
    .right-stage h2{
        display: none;
    }
    .right-stage div{
        display: none;
    }
    .entry-section-contin div{
        width: 12%;
    }
    .left-stage{
        border-left: none;    
    }


    .main-stage{
        flex-direction: column;
    }
    .entry-stage{
        flex-direction: column;
    }
    .article-cat-name-about{
        display: none;
    }
    .article-cat-name-about-vartical{
        display:block;
    }
    .article-cat-name{
        border:none ;
        margin-bottom: 5px;
    }
    .article-img{
        display: none;
    }
    .article-cat-name{
        display: none;
    }

} 

@media (max-width: 650px) {
    .section-contin div{
        width: 22%;
    }
    .aside-cat-name{
        display: none;

    }
    .cat-stage{
        height: auto;
        min-height: 445px;
    }
    .color-box-left{
        height: auto;
    }
    .main-stage{
        height: auto;
    }
} 

@media (min-height: 768px){
    .cat-stage{  margin: 150px 0px 0px 0px; }
    .color-box-left{left: 0px; top: 150px;}

}

@media (min-width: 1920px){
    .cat-stage{  margin: 150px 0px 0px 0px; height: 640px;}
    .color-box-left{left: 0px; top: 150px;height: 640px;}
    .color-box-left div{width: 300px;height: 88px;margin-bottom: 6px;border: none;}
    .color-box-left button{left: -285px;width: 100%; height: 100%;padding-left: 20px;font-size: 1.6em;}
    .cat-stage h1{font-size: 2em;}
    .main-stage{height: 580px;width: 93%;font-size: 1.2em;line-height: 1.5em;}
    .article-cat-name{flex: 25%;font-size: 1.1em;line-height: 1.8em; }
    .section-cat-name{flex: 75%;}
    .section-contin div{width: 10%; padding: 1%; margin: 1% 3% 1% 3% ;}
    .aside-cat-name{top: 300px;right: -170px;height: 50px;font-size: 2.9em;}
    .entry-aside-cat-name{top: 300px;right: -170px;height: 50px;font-size: 2.9em;}
    .entry-cat-stage{ margin: 70px 0 0 0; padding: 5px 0px 5px 20px ;}
    .color-box-left-inpage{left: 0px; top: 70px;height: 840px;}
    .color-box-left-inpage div{width: 300px;height: 107px;margin-bottom: 6px;border: none;}
    .color-box-left-inpage button{left: -285px;width: 100%; height: 100%;padding-left: 20px;font-size: 1.6em;}
    .data-accordion{font-size: 1.2em;line-height: 1.5em;}
    .data-accordion h4{font-size: 1.2em;line-height: 1.1em; margin-bottom: 10px;}
    .section-contin-accordion h2{font-size: 1.3em;line-height: 1em;}  
    
}